import Rails from '@rails/ujs'
import 'jquery-ui'
import 'jquery-ui/ui/widgets/sortable'

export default class Sortable {

  static selector = '[data-behavior~="sortable"]'

  static start() {
    const elements = document.querySelectorAll(this.selector)

    for (let element of elements) {
      new Sortable(element)
    }
  }

  constructor(element) {
    this.element = element

    this.bindEvents()
    this.build()
  }

  bindEvents() {
    this.build  = this.build.bind(this)
  }

  build() {
    $(this.element).find('tbody').sortable({
      dropOnEmpty: false,
      handle: '.handle',
      helper: this.helper,
      opacity: 0.4,
      scroll: true,
      update: this.update
    })
  }

  // Helper para que no colapsen las filas de la tabla al arrastrarlas.
  helper(e, ui) {
    ui.children().each( () => $(this).width($(this).width()) )

    return ui
  }

  update(e, ui) {
    Rails.ajax({
      url: $(e.target).data('reorder'),
      type: 'PATCH',
      data: $(e.target).sortable('serialize', { attribute: 'data-id' })
    })
  }
}
