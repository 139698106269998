// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/activestorage').start()

import Rails from '@rails/ujs'
// import Turbolinks from 'turbolinks'
import 'jquery'
import 'bootstrap-sass'
import '../src/public/common.min'
import 'jquery-parallax.js'
import 'owl.carousel'
import 'magnific-popup'
import theme from '../src/public/theme.init'
import main from '../src/public/main'
import { init } from '../src/features'

import '../stylesheets/public'

Rails.start()
// Turbolinks.start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

$(document).ready(init)
$(document).ready(theme)
$(document).ready(main)
