import DataTable from './components/data_table'
import Icheck from './components/icheck'
import Select2 from './components/select2'
import Sortable from './components/sortable'
import Upload from './components/upload'

export default function start() {
  init()
  DataTable.start()
  Icheck.start()
  Select2.start()
  Sortable.start()
  Upload.start()
}

export function init() {
  let url = window.location.pathname

  $(`[href='${url}']`)
    .not("[data-method='post']")
    .not("[data-method='delete']")
    .parents('li')
    .addClass('active')
}
