import 'blueimp-file-upload/js/jquery.fileupload-ui'

export default class Upload {

  static selector = '[data-behavior~="upload"]'

  static start() {
    const elements = document.querySelectorAll(this.selector)

    for (let element of elements) {
      new Upload(element)
    }
  }

  constructor(element) {
    this.element = element

    this.bindEvents()
    this.build()
  }

  bindEvents() {
    this.build  = this.build.bind(this)
  }

  build() {
    let form = $(this.element)

    form.fileupload({
      progressall: (e, data) => {
        let progress = parseInt(data.loaded / data.total * 100, 10)
        $('#progress-all .bar').css('width', progress + '%')
      }
    })

    form.addClass('fileupload-processing')

    $.ajax({
      url: form.fileupload('option', 'url'),
      dataType: 'json',
      context: form[0]
    })
      .always(function() {
        form.removeClass('fileupload-processing')
      })
      .done(function(files) {
        let fu = form.data('blueimp-fileupload') || form.data('fileupload');
        let template = fu._renderDownload(files).appendTo(form.find('.files'))
        fu._reflow = fu._transition && template.length && template[0].offsetWidth
        template.addClass('in')
      });
  }
}
