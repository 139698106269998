import WOW from 'wow.js'

export default function main() {
  let flag = false

  $(window).on('scroll', () => {
    let scroll = $(window).scrollTop()

    if (scroll > 0) {
      if (!flag) {
        $(".navbar").css({"background-color": "rgba(0, 0, 0, 100)"})
        $(".ul.menu li a").css({"color": "#000", "font-weight": "500"})
        flag = true
      }
    } else {
      if (flag) {
        $(".navbar").css({"background-color": "rgba(0, 0, 0, 0.5)"})
        $(".ul.menu li  a").css({"color": "#fff", "font-weight": "500"})
        flag = false
      }
    }
  })

  let lastScrollTop = 0
  $(window).on('scroll', () => {

    if ($(window).scrollTop() > 0) {
      $('.navbar').addClass('scrolled')
    }

    if ($(window).scrollTop() < 1) {
      $('.navbar').removeClass('scrolled')
    }
  })

  let hamburger = document.querySelector(".hamburger")
  hamburger.addEventListener("click", () => {
    hamburger.classList.toggle("is-active")
  })

 /* SMOOTH SCROLLING */

  $('a[href^="#"]').on('click', (event) => {
    let target = $( $(this).attr('href') )

    if ( target.length ) {
      event.preventDefault()
      $('html, body').animate({
        scrollTop: target.offset().top - 55
      }, 500)
    }
  });

  new WOW().init()

  $(document).on('click', '.navbar-collapse.show', (e) => {
    $(this).collapse('hide')
  })
}
